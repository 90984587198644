<template>
  <div>
    <div class="text-center m-8" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-container v-else-if="getUserIsAdministrator && !getClientInContext">
      <p>Which client workflows would you like to view?</p>
      <b-form-select
        v-model="administratorClientSelection"
        @change="setClientAndWorkflows"
        :options="clientOptions"
      ></b-form-select>
    </b-container>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: true,
      clientOptions: [],
      administratorClientSelection: null,
      client: null,
    };
  },
  computed: {
    ...mapGetters([
      "getClients",
      "getUserIsAdministrator",
      "getClientInContext",
    ]),
  },
  async mounted() {
    try {
      this.client = this.getClientInContext;

      if (!this.getUserIsAdministrator && !this.client) {
        this.$router.push({ name: "Home" }).catch(() => {});
        return;
      } else if (!this.client) {
        if (!Array.isArray(this.getClients) || this.getClients.length == 0) {
          await this.$store.dispatch("getClientInstances");
        }

        const clientID = this.$route.params.clientID;
        this.client = await this.$store.dispatch("getClientByID", clientID);

        if (!this.client) {
          if (this.clientOptions.length - 1 != this.getClients.length) {
            if (Array.isArray(this.getClients) && this.getClients.length > 0) {
              this.clientOptions = [
                { value: null, text: "Please select a client" },
              ];
              for (let i in this.getClients) {
                this.clientOptions.push({
                  value: this.getClients[i],
                  text: this.getClients[i].client_name,
                });
              }
            }
          } else {
            this.clientOptions = [
              {
                value: null,
                text:
                  "There are no clients, please add a client to view workflows",
              },
            ];
          }

          return;
        }
      }

      // load notification templates && users.
      try {
        await this.$store.dispatch("getClientUsers");
      } catch (err) {
        // pass
      }

      await this.$store.dispatch("getClientNotificationTemplates");
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async setClientAndWorkflows() {
      try {
        this.loading = true;
        if (this.getUserIsAdministrator) {
          await this.$store.dispatch(
            "getClientInContext",
            this.administratorClientSelection
          );
          try {
            await this.$store.dispatch("getClientUsers");
          } catch (err) {
            // pass
          }
          await this.$store.dispatch("getClientNotificationTemplates");
          await this.$router
            .push({
              name: "ClientWorkflows",
              params: { clientID: this.getClientInContext.client_id },
            })
            .catch(() => {});
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
